import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { jsPDF } from 'jspdf';
import './InvoicePreviewModal.css';
import axiosInstance from './utils/axiosInstance'
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';


// Komponenta za unos e-mail adrese
const EmailModal = ({ isOpen, onRequestClose, email, onSend, isLoading, isSuccess }) => {
  const [emailInput, setEmailInput] = useState(email || '');
  const [suggestions, setSuggestions] = useState([]);

  // Resetiraj emailInput kada se modal otvori
  useEffect(() => {
    if (isOpen) {
      setEmailInput(email || ''); // Ažuriraj email na osnovu prop-a
    }
  }, [isOpen, email]);

  const handleSend = () => {
    onSend(emailInput);
  };

  const handleEmailChange = async (e) => {
    const value = e.target.value;
    setEmailInput(value);

    if (value) {
      try {
        const response = await axios.get(`${API_URL}/clients/search`, {
          params: { email: value },
          withCredentials: true,
        });
        setSuggestions(response.data);
      } catch (error) {
        console.error('Error fetching email suggestions:', error);
      }
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestedEmail) => {
    setEmailInput(suggestedEmail);
    setSuggestions([]);
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} className="modal-content2">
      <h2 style={{ color: 'white', textAlign: 'center' }}>Pošalji račun na e-mail</h2>
      {isLoading ? (
        <div className="loading-message">
          Slanje emaila...
          <div className="loader"></div>
        </div>
      ) : isSuccess ? (
        <div className="success-message">
          Slanje uspješno
          <span className="checkmark">✔</span>
        </div>
      ) : (
        <>
          <input
            type="email"
            value={emailInput}
            onChange={handleEmailChange}
            placeholder="Upiši e-mail"
            style={{ width: '100%', marginBottom: '10px' }}
          />
          {suggestions.length > 0 && (
            <ul className="suggestions-list">
              {suggestions.map((suggestion) => (
                <li key={suggestion.id} onClick={() => handleSuggestionClick(suggestion.email)}>
                  {suggestion.email}
                </li>
              ))}
            </ul>
          )}
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <button onClick={handleSend}>Pošalji</button>
            <button onClick={onRequestClose}>Zatvori</button>
          </div>
        </>
      )}
    </Modal>
  );
};


const InvoicePreviewModal = ({ isOpen, onRequestClose, invoiceImageUrl, invoiceData }) => {
  const [scale, setScale] = useState(1);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [email, setEmail] = useState(invoiceData?.customer?.email || '');
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    const handlePreview = () => {
      if(invoiceImageUrl)
        setScale(1.6); // Na primjer, 50% za preview
    };
    if (isOpen) {
      handlePreview(); // Pozovi handlePreview kada se modal otvori
      if(invoiceData)
      {
        setEmail(invoiceData.customer?.email || ''); // Ažuriraj email ako je invoiceData dostupna
      }

    }
  }, [isOpen, invoiceData, invoiceImageUrl]);

  if (!invoiceData) {
    return null; // ili možeš dodati loader ili poruku o grešci
  }


  const saveAsPDF = async () => {
    try {
      const isInvoice = invoiceData.invoiceNumber?true:false;
      const response = await fetch(`${API_URL}/${isInvoice?`invoices`:`quotations`}/generate-pdf/${new Date(invoiceData.date).getFullYear()}/${isInvoice?invoiceData.invoiceNumber:invoiceData.quotationNumber}`, {
        method: 'GET',
        credentials: 'include', // Osigurava slanje kolačića s pozivom
      });
  
      if (!response.ok) {
        throw new Error('Greška pri dohvaćanju PDF-a');
      }
  
      const blob = await response.blob();
      const downloadLink = document.createElement('a');
      const url = window.URL.createObjectURL(blob);
      downloadLink.href = url;
      downloadLink.download = `${invoiceData.invoiceNumber ? `Racun-${invoiceData.invoiceNumber}-P1-1` : `Ponuda-${invoiceData.quotationNumber}`}.pdf`;
      downloadLink.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Greška pri spremanju PDF-a:', error);
    }
  };
  



  const handleSendEmail = () => {
    setIsEmailModalOpen(true); // Otvori modal za unos e-mail adrese
  };

  const handleEmailSend = async (email) => {
    setIsLoading(true);
    setIsSuccess(false);
    const isInvoice = invoiceData.invoiceNumber ? true : false;
    const year = new Date(invoiceData.date).getFullYear(); // Get the year from invoiceData
  
    try {
      const response = await fetch(`${API_URL}/${isInvoice ? `invoices` : `quotations`}/generate-pdf/${year}/${isInvoice ? invoiceData.invoiceNumber : invoiceData.quotationNumber}`, {
        method: 'GET',
        credentials: 'include', // Ensure cookies are sent with the request
      });
  
      if (!response.ok) {
        throw new Error('Error fetching PDF');
      }
  
      const pdfBlob = await response.blob();
      const reader = new FileReader();
  
      reader.onloadend = async () => {
        const base64data = reader.result; // Base64 string
  
        try {
          await axiosInstance.post(`${API_URL}/send-invoice`, {
            email,
            pdf: base64data, // Send base64 PDF
            invoiceNumber: isInvoice ? invoiceData.invoiceNumber : invoiceData.quotationNumber,
            operator: invoiceData.operator,
            isInvoice: isInvoice,
            year: year // Send the year as an argument
          },
          {
            withCredentials: true,
            headers: {
              'Content-Type': 'application/json',
            },
          });
  
          setIsSuccess(true);
          setTimeout(() => {
            setIsEmailModalOpen(false); // Close modal after sending
            setIsSuccess(false);
          }, 2000);
        } catch (error) {
          console.error('Error sending email:', error);
          alert('Error sending email.');
        } finally {
          setIsLoading(false);
        }
      };
  
      reader.readAsDataURL(pdfBlob); // Convert blob to base64
    } catch (error) {
      console.error('Error fetching PDF:', error);
      setIsLoading(false);
    }
  };
  

  return (
    <>
      <Modal isOpen={isOpen} onRequestClose={onRequestClose} className="modal-content2">
        <div>
        <h2 style={{ color: 'white' }}>
          {invoiceData.invoiceNumber ? 'Pregled računa' : 'Pregled ponude'}
        </h2>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
            <button onClick={saveAsPDF} style={{ marginRight: '10px' }}>Spremi kao PDF</button>
            <button onClick={handleSendEmail} style={{ marginRight: '10px' }}>Pošalji na mail</button>
            <button onClick={onRequestClose}>Zatvori</button>
          </div>
          <div style={{ 
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'flex-start', 
              maxHeight: 'calc(80vh - 80px)', 
              overflowY: 'auto', 
              marginTop: '0px',
              paddingLeft: window.innerWidth <= 1024 ? '60px' : '0px' // Add padding-left for mobile devices
            }}>
            <img src={invoiceImageUrl} style={{ transform: `scale(${scale})`, marginTop: '150px' }} alt="Invoice Preview" />
          </div>
        </div>
      </Modal>

      {/* Modal za unos e-mail adrese */}
      <EmailModal
        isOpen={isEmailModalOpen}
        onRequestClose={() => setIsEmailModalOpen(false)}
        email={email}
        onSend={handleEmailSend}
        isLoading={isLoading}
        isSuccess={isSuccess}
      />
    </>
  );
};

export default InvoicePreviewModal;
